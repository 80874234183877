import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`We`}</a>{` recently surpassed 33 million messages processed!`}</p>
    <p>{`Given three months worth of data processed, we wanted to highlight some interesting findings.`}</p>
    <p>{`The stats below are based on Facebook Messenger bots only.`}</p>
    <h2>{`Sessions`}</h2>
    <p>{`The average messages per session and time per session across all bots jumped quite a bit from June to July and appear to be heading to some consistency. In June, the average messages per session was 9.81 and the average time per session was 68 seconds. In July, the average messages increased to 12.62 and time increased to 139 seconds. August numbers appear to be a leveling off from July, with the average number of messages at 12.18 and the average time per session at 160 seconds.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/09/07155148/0_E8LKpPmqhKvDQyf2.png",
          "alt": null
        }}></img></figure>{`You had me at… “hi”`}</h2>
    <p>{`Across all the Facebook bots on our platform, one of the most common messages received is “hi” — and the variations of it.`}</p>
    <p>{`From a case sensitive standpoint, 65.7% of bots received the message “hi,” and 72% received a case insensitive variation of “hi.”`}</p>
    <p>{`It’s a great way to start a conversation.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/09/07155147/0_eRcGwqB3cMnJXxp8.png",
          "alt": null
        }}></img></figure>{`Does your bot support common messages?`}</h2>
    <p>{`In addition to “hi” and “hello”, rounding out the top 10 were “?”, “hey”, “help”, “yes”, and the thumbs up icon.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155147/62a1d-1tzm_xkhmvwvzz9mmldhxva.png)
    </figure>
The beauty about conversational interfaces is that your users will tell you
exactly what they want and what they think of your bot.
    <p>{`Adding support for these types of messages can help improve the overall engagement of your bot.`}</p>
    <p>{`Are you users asking for help? And how are they asking?`}</p>
    <p>{`With `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, you can see the top messages into your bot as well as follow the message flow through your bot to see how users interact and how the bot is responding. It’s a great way to identify areas where the bot may be breaking down and going back to add support for these messages.`}</p>
    <h2>{`Got Images?`}</h2>
    <p>{`One of the more interesting aspects of bots is all the unstructured data — users can send in images, video, animated gifs, locations, as well as their own words.`}</p>
    <p>{`Is your bot setup to handle these different message types?`}</p>
    <p>{`Some of our customers who noticed an increase in images being sent in to their bots, enabled support for the images — responding to the users, and thus creating a personality that is more engaging and keeps their users coming back.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` enables you to filter messages based on the content type to get a better idea of what users are sending in to your bot so you can configure your bot to handle the content.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155146/0_ap66fX8qax2qMOqn.png)
    </figure>
Here are the top Facebook Stickers sent over the past three months:
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/09/07155146/0_UydvbbG__QBcbam_-433x1024.png",
          "alt": null
        }}></img></figure>{`A guy walks into a bar…`}</h2>
    <p>{`and asks a bot for a joke.`}</p>
    <p>{`About 12% of Facebook bots on our platform have had users ask the bot to tell a joke or say something funny.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155145/343de-1ss-hlwhiw5fdqbht5ctqpa.png)
    </figure>
This is another opportunity to add additional personality to your bot, which can
help increase overall engagement.
    <h2>{`Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables bot developers and enterprises to increase user acquisition, engagement, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to standard metrics like engagement and retention, we have bot specific metrics like conversational analytics, sentiment analysis, and the full chat session transcripts.`}</p>
    <p>{`We also provide tools to take action on the data — like our live person take over of sessions to help lead a user to conversion and increase engagement.`}</p>
    <p>{`You can sign up for free at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io./"
      }}>{`www.dashbot.io.`}</a></p>
    <figure></figure>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      